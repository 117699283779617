/* Start::button default */
.btn {
  font-size: 0.85rem;
  border-radius: 0.35rem;
  padding: 0.5rem 0.85rem;
  box-shadow: none;
  font-weight: 500;
  &.btn-sm {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
  }
  &.btn-lg {
    padding: 0.65rem 1rem;
    border-radius: $default-radius;
    font-size: 0.95rem;
  }
}
.btn-group {
  .btn {
    padding: 0.45rem 0.75rem;
  }
}
.btn-group-lg {
  .btn {
    padding: 0.65rem 1rem;
  }
}
.btn-group-sm {
  .btn {
    padding: 0.25rem 0.5rem;
  }
}
.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active {
  color: $white;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
/* End::button default */

/* Start::button colors */
@mixin btn-primary($btn-color) {
  background-color: rgb(#{$btn-color}) !important;
  border-color: rgb(#{$btn-color}) !important;
  color: $white !important;
  &:hover {
    background-color: rgba(#{$btn-color}, 0.9) !important;
    border-color: rgb(#{$btn-color}) !important;
    color: $white;
  }
  &:focus {
    background-color: rgb(#{$btn-color});
    border-color: rgb(#{$btn-color});
    color: $white;
  }
}
@mixin btn-check-focus($btn-color) {
  background-color: rgb(#{$btn-color});
  border-color: rgb(#{$btn-color});
  outline: 0;
  box-shadow: none;
}
/* btn-primary starts */
.btn-primary {
  @include btn-primary(var(--primary-rgb));
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  @include btn-check-focus(var(--primary-rgb));
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary.show:focus,
.btn-primary:active:focus {
  box-shadow: 0 0.25rem 1rem $primary-05;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary.show,
.btn-primary:active {
  background-color: $primary !important;
  border-color: $primary-border !important;
}
/* btn-primary ends */

/* btn-secondary starts */
.btn-secondary {
  @include btn-primary(var(--secondary-rgb));
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  @include btn-check-focus(var(--secondary-rgb));
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary.show:focus,
.btn-secondary:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary.show,
.btn-secondary:active {
  background-color: $secondary !important;
  border-color: $secondary !important;
}
/* btn-secondary ends */

/* btn-warning starts */
.btn-warning {
  @include btn-primary(var(--warning-rgb));
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  @include btn-check-focus(var(--warning-rgb));
}
.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning.show:focus,
.btn-warning:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}
.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning.show,
.btn-warning:active {
  background-color: $warning !important;
  border-color: $warning !important;
}
/* btn-warning ends */

/* btn-info starts */
.btn-info {
  @include btn-primary(var(--info-rgb));
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  @include btn-check-focus(var(--info-rgb));
}
.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info.show:focus,
.btn-info:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}
.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info.show,
.btn-info:active {
  background-color: $info !important;
  border-color: $info !important;
}
/* btn-info ends */

/* btn-danger starts */
.btn-danger {
  @include btn-primary(var(--danger-rgb));
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  @include btn-check-focus(var(--danger-rgb));
}
.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger.show:focus,
.btn-danger:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}
.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger.show,
.btn-danger:active {
  background-color: $danger !important;
  border-color: $danger !important;
}
/* btn-danger ends */

/* btn-success starts */
.btn-success {
  @include btn-primary(var(--success-rgb));
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  @include btn-check-focus(var(--success-rgb));
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success.show:focus,
.btn-success:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success.show,
.btn-success:active {
  background-color: $success !important;
  border-color: $success !important;
}
/* btn-success ends */

/* btn-dark starts */
.btn-dark {
  background-color: $dark;
  border-color: $dark;
  color: $custom-white;
  &:hover,
  &:focus,
  &:active {
    background-color: $dark;
    border-color: $dark;
    color: $custom-white !important;
  }
  svg {
    fill: $custom-black;
  }
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  @include btn-check-focus(var(--dark-rgb));
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark.show:focus,
.btn-dark:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--dark-rgb), 0.5);
}
.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark.show,
.btn-dark:active {
  background-color: $dark !important;
  border-color: $dark !important;
  color: $custom-white;
}
/* btn-dark ends */

/* btn-light starts */
.btn-light {
  background-color: $light;
  border-color: $default-border;
  color: $default-text-color;
  &:hover {
    background-color: #e4ecf2;
    border-color: $light;
    color: $default-text-color;
  }
  &:focus {
    background-color: $light;
    border-color: $light;
    box-shadow: none;
    color: $default-text-color;
  }
}
[data-theme-mode="dark"] {
  .btn-light:hover {
    background-color: rgba(var(--light-rgb), 0.8);
  }
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  @include btn-check-focus(var(--light-rgb));
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light.show:focus,
.btn-light:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--light-rgb), 0.5);
}
.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light.show,
.btn-light:active {
  background-color: $light !important;
  border-color: $light !important;
  color: $default-text-color !important;
}
/* btn-light ends */

/* btn-teal starts */
.btn-teal {
  @include btn-primary(var(--teal-rgb));
}
.btn-check:focus + .btn-teal,
.btn-teal:focus {
  @include btn-check-focus(var(--teal-rgb));
}
.btn-check:active + .btn-teal:focus,
.btn-check:checked + .btn-teal:focus,
.btn-teal.active:focus,
.btn-teal.show:focus,
.btn-teal:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}
.btn-check:active + .btn-teal,
.btn-check:checked + .btn-teal,
.btn-teal.active,
.btn-teal.show,
.btn-teal:active {
  background-color: $teal !important;
  border-color: $teal !important;
}
/* btn-teal ends */

/* btn-purple starts */
.btn-purple {
  @include btn-primary(var(--purple-rgb));
}
.btn-check:focus + .btn-purple,
.btn-purple:focus {
  @include btn-check-focus(var(--purple-rgb));
}
.btn-check:active + .btn-purple:focus,
.btn-check:checked + .btn-purple:focus,
.btn-purple.active:focus,
.btn-purple.show:focus,
.btn-purple:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.5);
}
.btn-check:active + .btn-purple,
.btn-check:checked + .btn-purple,
.btn-purple.active,
.btn-purple.show,
.btn-purple:active {
  background-color: $purple !important;
  border-color: $purple !important;
}
/* btn-purple ends */

/* btn-orange starts */
.btn-orange {
  @include btn-primary(var(--orange-rgb));
}
.btn-check:focus + .btn-orange,
.btn-orange:focus {
  @include btn-check-focus(var(--orange-rgb));
}
.btn-check:active + .btn-orange:focus,
.btn-check:checked + .btn-orange:focus,
.btn-orange.active:focus,
.btn-orange.show:focus,
.btn-orange:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--orange-rgb), 0.5);
}
.btn-check:active + .btn-orange,
.btn-check:checked + .btn-orange,
.btn-orange.active,
.btn-orange.show,
.btn-orange:active {
  background-color: $orange !important;
  border-color: $orange !important;
}
/* btn-orange ends */

/* btn-pink starts */
.btn-pink {
  @include btn-primary(var(--pink-rgb));
}
.btn-check:focus + .btn-pink,
.btn-pink:focus {
  @include btn-check-focus(var(--pink-rgb));
}
.btn-check:active + .btn-pink:focus,
.btn-check:checked + .btn-pink:focus,
.btn-pink.active:focus,
.btn-pink.show:focus,
.btn-pink:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--pink-rgb), 0.5);
}
.btn-check:active + .btn-pink,
.btn-check:checked + .btn-pink,
.btn-pink.active,
.btn-pink.show,
.btn-pink:active {
  background-color: $pink !important;
  border-color: $pink !important;
}
/* btn-pink ends */

/* End::button colors */

/* Start::button outline colors */

@mixin btn-outline($btn-outline-color) {
  color: rgb(#{$btn-outline-color});
  border-color: rgb(#{$btn-outline-color});
  &:hover,
  &:active {
    color: $white;
    background-color: rgb(#{$btn-outline-color});
    border-color: rgb(#{$btn-outline-color});
  }
  &:focus {
    color: $white;
    background-color: rgb(#{$btn-outline-color});
    border-color: rgb(#{$btn-outline-color});
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
  }
}

/* btn-outline-primary start */
.btn-outline-primary {
  @include btn-outline(var(--primary-rgb));
}
/* btn-outline-primary end */

/* btn-outline-secondary start */
.btn-outline-secondary {
  @include btn-outline(var(--secondary-rgb));
}
/* btn-outline-secondary end */

/* btn-outline-warning start */
.btn-outline-warning {
  @include btn-outline(var(--warning-rgb));
}
/* btn-outline-warning end */

/* btn-outline-danger start */
.btn-outline-danger {
  @include btn-outline(var(--danger-rgb));
}
/* btn-outline-danger end */

/* btn-outline-success start */
.btn-outline-success {
  @include btn-outline(var(--success-rgb));
}
/* btn-outline-success end */

/* btn-outline-info start */
.btn-outline-info {
  @include btn-outline(var(--info-rgb));
}
/* btn-outline-info end */

/* btn-outline-light start */
.btn-outline-light {
  color: $default-text-color !important;
  border-color: $default-border;
  &:hover,
  &:active {
    color: $default-text-color;
    background-color: $light;
    border-color: $light;
  }
  &:focus {
    color: $default-text-color;
    background-color: $light;
    border-color: $light;
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
  }
}
/* btn-outline-light end */

/* btn-outline-dark start */
.btn-outline-dark {
  color: $dark;
  border-color: $dark;
  &:hover,
  &:active {
    color: $custom-white;
    background-color: $custom-black;
    border-color: $custom-black;
  }
  &:focus {
    color: $custom-white;
    background-color: $dark;
    border-color: $dark;
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
  }
}
/* btn-outline-dark end */

/* btn-outline-teal start */
.btn-outline-teal {
  @include btn-outline(var(--teal-rgb));
}
/* btn-outline-teal end */

/* End::button outline colors */

/* Start::button disabled colors */

/* btn-primary-disabled start */
.btn-primary.disabled,
.btn-primary:disabled {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
/* btn-primary-disabled end */

/* btn-secondary-disabled start */
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;
}
/* btn-secondary-disabled end */

/* End::button disabled colors */

/* Start::button transparent */
@mixin btn-transparent($btn-transparent) {
  background-color: rgba(#{$btn-transparent}, 0.1);
  border-color: rgba(#{$btn-transparent}, 0.1);
  color: rgb(#{$btn-transparent});
  &:hover,
  &:focus:hover {
    background-color: rgb(#{$btn-transparent});
    border-color: rgb(#{$btn-transparent});
    color: $white;
  }
}
@mixin btn-transparent-focus($btn-transparent) {
  background-color: rgba(#{$btn-transparent}, 0.1);
  border-color: rgba(#{$btn-transparent}, 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(#{$btn-transparent}, 0.3);
  color: rgb(#{$btn-transparent});
}
/* btn-primary-transparent starts */
.btn-primary-transparent {
  @include btn-transparent(var(--primary-rgb));
}
.btn-check:focus + .btn-primary-transparent,
.btn-primary-transparent:focus {
  @include btn-transparent-focus(var(--primary-rgb));
}
.btn-check:active + .btn-primary-transparent:focus,
.btn-check:checked + .btn-primary-transparent:focus,
.btn-primary-transparent.active:focus,
.btn-primary-transparent.show:focus,
.btn-primary-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem $primary-05;
}
.btn-check:active + .btn-primary-transparent,
.btn-check:checked + .btn-primary-transparent,
.btn-primary-transparent.active,
.btn-primary-transparent.show,
.btn-primary-transparent:active {
  background-color: $primary;
  border-color: $primary-01;
  color: $white;
}
/* btn-primary-transparent ends */

/* btn-secondary-transparent starts */
.btn-secondary-transparent {
  @include btn-transparent(var(--secondary-rgb));
}
.btn-check:focus + .btn-secondary-transparent,
.btn-secondary-transparent:focus {
  @include btn-transparent-focus(var(--secondary-rgb));
}
.btn-check:active + .btn-secondary-transparent:focus,
.btn-check:checked + .btn-secondary-transparent:focus,
.btn-secondary-transparent.active:focus,
.btn-secondary-transparent.show:focus,
.btn-secondary-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}
.btn-check:active + .btn-secondary-transparent,
.btn-check:checked + .btn-secondary-transparent,
.btn-secondary-transparent.active,
.btn-secondary-transparent.show,
.btn-secondary-transparent:active {
  background-color: $secondary;
  border-color: rgba(var(--secondary-rgb), 0.1);
  color: $white;
}

/* btn-secondary-transparent ends */

/* btn-warning-transparent starts */
.btn-warning-transparent {
  @include btn-transparent(var(--warning-rgb));
}
.btn-check:focus + .btn-warning-transparent,
.btn-warning-transparent:focus {
  @include btn-transparent-focus(var(--warning-rgb));
}
.btn-check:active + .btn-warning-transparent:focus,
.btn-check:checked + .btn-warning-transparent:focus,
.btn-warning-transparent.active:focus,
.btn-warning-transparent.show:focus,
.btn-warning-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}
.btn-check:active + .btn-warning-transparent,
.btn-check:checked + .btn-warning-transparent,
.btn-warning-transparent.active,
.btn-warning-transparent.show,
.btn-warning-transparent:active {
  background-color: $warning;
  border-color: rgba(var(--warning-rgb), 0.1);
  color: $white;
}

/* btn-warning-transparent ends */

/* btn-info-transparent starts */
.btn-info-transparent {
  @include btn-transparent(var(--info-rgb));
}
.btn-check:focus + .btn-info-transparent,
.btn-info-transparent:focus {
  @include btn-transparent-focus(var(--info-rgb));
}
.btn-check:active + .btn-info-transparent:focus,
.btn-check:checked + .btn-info-transparent:focus,
.btn-info-transparent.active:focus,
.btn-info-transparent.show:focus,
.btn-info-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}
.btn-check:active + .btn-info-transparent,
.btn-check:checked + .btn-info-transparent,
.btn-info-transparent.active,
.btn-info-transparent.show,
.btn-info-transparent:active {
  background-color: $info;
  border-color: rgba(var(--info-rgb), 0.1);
  color: $white;
}

/* btn-info-transparent ends */

/* btn-success-transparent starts */
.btn-success-transparent {
  @include btn-transparent(var(--success-rgb));
}
.btn-check:focus + .btn-success-transparent,
.btn-success-transparent:focus {
  @include btn-transparent-focus(var(--success-rgb));
}
.btn-check:active + .btn-success-transparent:focus,
.btn-check:checked + .btn-success-transparent:focus,
.btn-success-transparent.active:focus,
.btn-success-transparent.show:focus,
.btn-success-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}
.btn-check:active + .btn-success-transparent,
.btn-check:checked + .btn-success-transparent,
.btn-success-transparent.active,
.btn-success-transparent.show,
.btn-success-transparent:active {
  background-color: $success;
  border-color: rgba(var(--success-rgb), 0.1);
  color: $white;
}

/* btn-success-transparent ends */

/* btn-danger-transparent starts */
.btn-danger-transparent {
  @include btn-transparent(var(--danger-rgb));
}
.btn-check:focus + .btn-danger-transparent,
.btn-danger-transparent:focus {
  @include btn-transparent-focus(var(--danger-rgb));
}
.btn-check:active + .btn-danger-transparent:focus,
.btn-check:checked + .btn-danger-transparent:focus,
.btn-danger-transparent.active:focus,
.btn-danger-transparent.show:focus,
.btn-danger-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}
.btn-check:active + .btn-danger-transparent,
.btn-check:checked + .btn-danger-transparent,
.btn-danger-transparent.active,
.btn-danger-transparent.show,
.btn-danger-transparent:active {
  background-color: $danger;
  border-color: rgba(var(--danger-rgb), 0.1);
  color: $white;
}

/* btn-danger-transparent ends */

/* btn-teal-transparent starts */
.btn-teal-transparent {
  @include btn-transparent(var(--teal-rgb));
}
.btn-check:focus + .btn-teal-transparent,
.btn-teal-transparent:focus {
  @include btn-transparent-focus(var(--teal-rgb));
}
.btn-check:active + .btn-teal-transparent:focus,
.btn-check:checked + .btn-teal-transparent:focus,
.btn-teal-transparent.active:focus,
.btn-teal-transparent.show:focus,
.btn-teal-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}
.btn-check:active + .btn-teal-transparent,
.btn-check:checked + .btn-teal-transparent,
.btn-teal-transparent.active,
.btn-teal-transparent.show,
.btn-teal-transparent:active {
  background-color: $teal;
  border-color: rgba(var(--teal-rgb), 0.1);
  color: $white;
}

/* btn-teal-transparent ends */

/* btn-purple-transparent starts */
.btn-purple-transparent {
  @include btn-transparent(var(--purple-rgb));
}
.btn-check:focus + .btn-purple-transparent,
.btn-purple-transparent:focus {
  @include btn-transparent-focus(var(--purple-rgb));
}
.btn-check:active + .btn-purple-transparent:focus,
.btn-check:checked + .btn-purple-transparent:focus,
.btn-purple-transparent.active:focus,
.btn-purple-transparent.show:focus,
.btn-purple-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.5);
}
.btn-check:active + .btn-purple-transparent,
.btn-check:checked + .btn-purple-transparent,
.btn-purple-transparent.active,
.btn-purple-transparent.show,
.btn-purple-transparent:active {
  background-color: $purple;
  border-color: rgba(var(--purple-rgb), 0.1);
  color: $white;
}

/* btn-purple-transparent ends */

/* End::button transparent */

/* Start::btn-sizes::sm */
.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
  border-radius: $default-radius;
}
/* Start::btn-sizes::sm */

/* Start::icon button */
.btn-icon {
  width: 2.313rem;
  height: 2.313rem;
  font-size: 0.95rem;
  flex-shrink: 0;
  i {
    padding: 0rem;
    margin: -0.5rem;
  }
  &.btn-sm {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.8rem;
  }
  &.btn-lg {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.2rem;
  }
}
/* End::icon button */

/* Start::button list */
.btn-list {
  button,
  div,
  a,
  input {
    margin: 0 0.375rem 0.375rem 0;
  }
}
[dir="rtl"] {
  .btn-list {
    button,
    div,
    a,
    input {
      margin: 0 0 0.375rem 0.375rem;
    }
  }
}

.btn-list {
  margin-block-end: -0.5rem;
}

.btn-bd-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: #082a73;
  --bs-btn-border-color: #082a73;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-color: #082a73;
  --bs-btn-hover-bg: rgba(#082a73, 0.9);
  --bs-btn-hover-border-color: rgba(#082a73, 0.9);
  --bs-btn-active-color: #082a73;
  --bs-btn-active-bg: rgba(#082a73, 0.9);
  --bs-btn-active-border-color: rgba(#082a73, 0.9);
}
/* End::button list */

@mixin btn-check-outline($btn-outline-color) {
  color: $white !important;
  background-color: rgb(#{$btn-outline-color}) !important;
  border-color: rgb(#{$btn-outline-color}) !important;
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.btn-check + .btn.btn-outline-primary:hover {
  @include btn-check-outline(var(--primary-rgb));
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active,
.btn-check + .btn.btn-outline-secondary:hover {
  @include btn-check-outline(var(--secondary-rgb));
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active,
.btn-check + .btn.btn-outline-info:hover {
  @include btn-check-outline(var(--info-rgb));
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active,
.btn-check + .btn.btn-outline-warning:hover {
  @include btn-check-outline(var(--warning-rgb));
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active,
.btn-check + .btn.btn-outline-danger:hover {
  @include btn-check-outline(var(--danger-rgb));
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active,
.btn-check + .btn.btn-outline-success:hover {
  @include btn-check-outline(var(--success-rgb));
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active,
.btn-outline-light:hover,
.btn-check + .btn.btn-outline-light:hover {
  color: $default-text-color !important;
  background-color: rgb(var(--light-rgb)) !important;
  border-color: rgb(var(--light-rgb)) !important;
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active,
.btn-check + .btn.btn-outline-dark:hover {
  color: $custom-white !important;
  background-color: $custom-black !important;
  border-color: $custom-black !important;
}
.btn-check:active + .btn-outline-teal,
.btn-check:checked + .btn-outline-teal,
.btn-outline-teal.active,
.btn-outline-teal.dropdown-toggle.show,
.btn-outline-teal:active,
.btn-check + .btn.btn-outline-teal:hover {
  @include btn-check-outline(var(--teal-rgb));
}
.btn-check:checked + .btn-outline-primary:focus {
  box-shadow: 0 0.25rem 1rem rgba($primary, 0.5);
}
.btn-check:checked + .btn-outline-secondary:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}
.btn-check:checked + .btn-outline-success:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}
.btn-check:checked + .btn-outline-warning:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}
.btn-check:checked + .btn-outline-info:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}
.btn-check:checked + .btn-outline-danger:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}
.btn-check:checked + .btn-outline-light:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--light-rgb), 0.5);
}
.btn-check:checked + .btn-outline-dark:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--dark-rgb), 0.5);
}
.btn-check:checked + .btn-outline-teal:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}
.btn-check:focus + .btn.btn-outline-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;
  box-shadow: none;
}
.btn-check:checked + .btn.btn-outline-secondary {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.3);
}
.btn.show.btn-outline-secondary {
  background-color: $secondary;
  color: $white;
  border-color: $secondary;
}

/* Start:: Light Buttons */
.btn {
  &.btn-primary-light {
    background-color: $primary-01;
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }
  }
  &.btn-secondary-light {
    background-color: rgba(var(--secondary-rgb), 0.1);
    color: $secondary;
    &:hover,
    &:focus,
    &:active {
      background-color: $secondary;
      color: $white;
      border-color: $secondary;
    }
  }
  &.btn-warning-light {
    background-color: rgba(var(--warning-rgb), 0.1);
    color: $warning;
    &:hover,
    &:focus,
    &:active {
      background-color: $warning;
      color: $white;
      border-color: $warning;
    }
  }
  &.btn-info-light {
    background-color: rgba(var(--info-rgb), 0.1);
    color: $info;
    &:hover,
    &:focus,
    &:active {
      background-color: $info;
      color: $white;
      border-color: $info;
    }
  }
  &.btn-success-light {
    background-color: rgba(var(--success-rgb), 0.1);
    color: $success;
    &:hover,
    &:focus,
    &:active {
      background-color: $success;
      color: $white;
      border-color: $success;
    }
  }
  &.btn-danger-light {
    background-color: rgba(var(--danger-rgb), 0.1);
    color: $danger;
    &:hover,
    &:focus,
    &:active {
      background-color: $danger;
      color: $white;
      border-color: $danger;
    }
  }
  &.btn-purple-light {
    background-color: rgba(var(--purple-rgb), 0.1);
    color: $purple;
    &:hover,
    &:focus,
    &:active {
      background-color: $purple;
      color: $white;
      border-color: $purple;
    }
  }
  &.btn-teal-light {
    background-color: rgba(var(--teal-rgb), 0.1);
    color: $teal;
    &:hover,
    &:focus,
    &:active {
      background-color: $teal;
      color: $white;
      border-color: $teal;
    }
  }
  &.btn-orange-light {
    background-color: rgba(var(--orange-rgb), 0.1);
    color: $orange;
    &:hover,
    &:focus,
    &:active {
      background-color: $orange;
      color: $white;
      border-color: $orange;
    }
  }
}
/* End:: Light Buttons */

/* Start:: Gradient Buttons */
.btn {
  &.btn-primary-gradient {
    background: $primary-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-secondary-gradient {
    background: $secondary-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-warning-gradient {
    background: $warning-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-info-gradient {
    background: $info-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-success-gradient {
    background: $success-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-danger-gradient {
    background: $danger-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-orange-gradient {
    background: $orange-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-teal-gradient {
    background: $teal-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  &.btn-purple-gradient {
    background: $purple-gradient;
    color: $white !important;
    border: 0;
    &:hover {
      border: 0;
    }
  }
}
/* End:: Gradient Buttons */

/* Start:: Ghost Buttons */
.btn {
  &.btn-primary-ghost {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    &:active {
      color: $primary;
      border: 1px solid $primary;
    }
  }
  &.btn-secondary-ghost {
    background-color: transparent;
    color: $secondary;
    border: 1px solid $secondary;
    &:active {
      color: $secondary;
      border: 1px solid $secondary;
    }
  }
  &.btn-warning-ghost {
    background-color: transparent;
    color: $warning;
    border: 1px solid $warning;
    &:active {
      color: $warning;
      border: 1px solid $warning;
    }
  }
  &.btn-info-ghost {
    background-color: transparent;
    color: $info;
    border: 1px solid $info;
    &:active {
      color: $info;
      border: 1px solid $info;
    }
  }
  &.btn-success-ghost {
    background-color: transparent;
    color: $success;
    border: 1px solid $success;
    &:active {
      color: $success;
      border: 1px solid $success;
    }
  }
  &.btn-danger-ghost {
    background-color: transparent;
    color: $danger;
    border: 1px solid $danger;
    &:active {
      color: $danger;
      border: 1px solid $danger;
    }
  }
  &.btn-orange-ghost {
    background-color: transparent;
    color: $orange;
    border: 1px solid $orange;
    &:active {
      color: $orange;
      border: 1px solid $orange;
    }
  }
  &.btn-teal-ghost {
    background-color: transparent;
    color: $teal;
    border: 1px solid $teal;
    &:active {
      color: $teal;
      border: 1px solid $teal;
    }
  }
  &.btn-purple-ghost {
    background-color: transparent;
    color: $purple;
    border: 1px solid $purple;
    &:active {
      color: $purple;
      border: 1px solid $purple;
    }
  }
}
/* End:: Ghost Buttons */

/* Start:: Width Buttons */
.btn {
  &.btn-w-xs {
    min-width: 5.625rem;
  }
  &.btn-w-sm {
    min-width: 6.975rem;
  }
  &.btn-w-md {
    min-width: 8.125rem;
  }
  &.btn-w-lg {
    min-width: 9.375rem;
  }
}
@media screen and (min-width: 576px) {
  .btn {
    &.btn-sm-w-xs {
      min-width: 5.625rem;
    }
    &.btn-sm-w-sm {
      min-width: 6.975rem;
    }
    &.btn-sm-w-md {
      min-width: 8.125rem;
    }
    &.btn-sm-w-lg {
      min-width: 9.375rem;
    }
  }
}
/* End:: Width Buttons */

/* Start:: Loading Buttons */
.btn-loader {
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;
  .loading {
    margin-inline-end: 0.75rem;
  }
  i {
    animation: loader 1000ms infinite linear;
    position: absolute !important;
    top: 0.25rem;
  }
  @-webkit-keyframes loader {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
/* End:: Loading Buttons */

/* Start:: Raised Buttons  */
.btn-raised-shadow:hover {
  box-shadow: 0 0.5rem 1rem 0.5rem $black-1;
}
/* End:: Raised Buttons  */

/* Start:: Label Buttons */
.label-btn {
  position: relative;
  padding-inline-start: 2.6rem;
}
.label-btn-icon {
  position: absolute;
  width: 2.25rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  inset-inline-start: -1px;
  inset-block-start: -1px;
  inset-block-end: -1px;
  background-color: $white-2;
}
.label-btn.label-end {
  padding-inline-start: 1rem;
  padding-inline-end: 2.6rem;
  .label-btn-icon {
    inset-inline-end: -1px;
    inset-inline-start: auto;
  }
}
/* End:: Label Buttons */

/* Start:: Custom Buttons */
.custom-button {
  position: relative;
  padding-inline-start: 2.75rem;
  .custom-btn-icons {
    box-shadow: 0px 0px 1px 0.25rem rgba(0, 0, 0, 0.1);
    position: absolute;
    inset-inline-start: -0.125rem;
    inset-block-start: 0px;
    background-color: $custom-white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0.375rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    width: 2.25rem;
    height: 2.25rem;
    i {
      position: absolute;
    }
  }
}
.btn-hover {
  position: relative;
  &.btn-hover-animate {
    transition: all 0.2s linear 0s;
    &:before {
      content: "\F417";
      font-family: bootstrap-icons !important;
      font-size: 0.8125rem;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      inset-inline-end: 0;
      inset-block-start: 0;
      opacity: 0;
      height: 100%;
      width: 2rem;
      transition: all 0.2s linear 0s;
    }
    &:hover {
      padding-inline-end: 2rem;
      &:before {
        opacity: 1;
        text-indent: 0;
      }
    }
  }
}
.btn-glare {
  border: 1px solid;
  overflow: hidden;
  position: relative;
  span {
    z-index: 20;
  }
  &:after {
    background: $custom-white;
    content: "";
    height: 4.6875rem;
    inset-inline-start: -1.625rem;
    opacity: 0.15;
    position: absolute;
    inset-block-start: -3.125rem;
    transform: rotate(35deg);
    transition: all 600ms cubic-bezier(0.2, 1, 0.25, 1);
    width: 1.875rem;
    z-index: 30;
  }
}
.btn-glare:hover {
  &:after {
    inset-inline-start: 100%;
    inset-block-start: 0;
    transition: all 600ms cubic-bezier(0.2, 1, 0.25, 1);
  }
}
.btn-darken-hover {
  position: relative;
  &:hover {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      inset-block-start: 0;
      inset-inline-start: 0;
    }
  }
}
.btn-custom-border {
  border: 0;
  box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), 0);
  outline: 1px solid;
  outline-color: rgba(var(--warning-rgb), 0.5);
  outline-offset: 0;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  &:hover {
    border: 0 solid;
    box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), 0.5),
      0 0 1.25rem rgba(var(--warning-rgb), 0.2);
    outline-color: rgba(var(--warning-rgb), 0);
    outline-offset: 0.938rem;
  }
}
/* End:: Custom Buttons */

/* Start:: Button Borders */
.btn-border-down {
  &.btn-primary-light {
    border-block-end: 0.1875rem solid $primary;
  }
  &.btn-secondary-light {
    border-block-end: 0.1875rem solid $secondary;
  }
  &.btn-info-light {
    border-block-end: 0.1875rem solid $info;
  }
  &.btn-warning-light {
    border-block-end: 0.1875rem solid $warning;
  }
  &.btn-success-light {
    border-block-end: 0.1875rem solid $success;
  }
  &.btn-danger-light {
    border-block-end: 0.1875rem solid $danger;
  }
  &.btn-dark-light {
    border-block-end: 0.1875rem solid $primary;
  }
  &.btn-teal-light {
    border-block-end: 0.1875rem solid $teal;
  }
  &.btn-purple-light {
    border-block-end: 0.1875rem solid $purple;
  }
}
.btn-border-start {
  &.btn-primary-light {
    border-inline-start: 0.1875rem solid $primary;
  }
  &.btn-secondary-light {
    border-inline-start: 0.1875rem solid $secondary;
  }
  &.btn-info-light {
    border-inline-start: 0.1875rem solid $info;
  }
  &.btn-warning-light {
    border-inline-start: 0.1875rem solid $warning;
  }
  &.btn-success-light {
    border-inline-start: 0.1875rem solid $success;
  }
  &.btn-danger-light {
    border-inline-start: 0.1875rem solid $danger;
  }
  &.btn-dark-light {
    border-inline-start: 0.1875rem solid $primary;
  }
  &.btn-teal-light {
    border-inline-start: 0.1875rem solid $teal;
  }
  &.btn-purple-light {
    border-inline-start: 0.1875rem solid $purple;
  }
}
.btn-border-end {
  &.btn-primary-light {
    border-inline-end: 0.1875rem solid $primary;
  }
  &.btn-secondary-light {
    border-inline-end: 0.1875rem solid $secondary;
  }
  &.btn-info-light {
    border-inline-end: 0.1875rem solid $info;
  }
  &.btn-warning-light {
    border-inline-end: 0.1875rem solid $warning;
  }
  &.btn-success-light {
    border-inline-end: 0.1875rem solid $success;
  }
  &.btn-danger-light {
    border-inline-end: 0.1875rem solid $danger;
  }
  &.btn-dark-light {
    border-inline-end: 0.1875rem solid $primary;
  }
  &.btn-teal-light {
    border-inline-end: 0.1875rem solid $teal;
  }
  &.btn-purple-light {
    border-inline-end: 0.1875rem solid $purple;
  }
}
.btn-border-top {
  &.btn-primary-light {
    border-block-start: 0.1875rem solid $primary;
  }
  &.btn-secondary-light {
    border-block-start: 0.1875rem solid $secondary;
  }
  &.btn-info-light {
    border-block-start: 0.1875rem solid $info;
  }
  &.btn-warning-light {
    border-block-start: 0.1875rem solid $warning;
  }
  &.btn-success-light {
    border-block-start: 0.1875rem solid $success;
  }
  &.btn-danger-light {
    border-block-start: 0.1875rem solid $danger;
  }
  &.btn-dark-light {
    border-block-start: 0.1875rem solid $primary;
  }
  &.btn-teal-light {
    border-block-start: 0.1875rem solid $teal;
  }
  &.btn-purple-light {
    border-block-start: 0.1875rem solid $purple;
  }
}
/* End:: Button Borders */

/* Start:: Social Buttons */
.btn-facebook {
  background-color: $facebook;
  color: $white;
  border: 1px solid $facebook;
  &:hover,
  &:focus,
  &:active {
    background-color: $facebook !important;
    color: $white !important;
    border: 1px solid $facebook !important;
  }
}
.btn-google {
  background-color: $google;
  color: $white;
  border: 1px solid $google;
  &:hover,
  &:focus,
  &:active {
    background-color: $google !important;
    color: $white !important;
    border: 1px solid $google !important;
  }
}
.btn-twitter {
  background-color: $twitter;
  color: $white;
  border: 1px solid $twitter;
  &:hover,
  &:focus,
  &:active {
    background-color: $twitter !important;
    color: $white !important;
    border: 1px solid $twitter !important;
  }
}
.btn-github {
  background-color: $github;
  color: $white;
  border: 1px solid $github;
  &:hover,
  &:focus,
  &:active {
    background-color: $github !important;
    color: $white !important;
    border: 1px solid $github !important;
  }
}
.btn-youtube {
  background-color: $youtube;
  color: $white;
  border: 1px solid $youtube;
  &:hover,
  &:focus,
  &:active {
    background-color: $youtube !important;
    color: $white !important;
    border: 1px solid $youtube !important;
  }
}
.btn-instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: $white;
  border: 1px solid transparent;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border: 1px solid transparent;
  }
}
/* End:: Social Buttons */

/* Start:: Checkbox buttons */
@media (max-width: "575.98px") {
  .checkbox-button-group,
  .radio-button-group {
    .btn-group {
      label {
        font-size: 0.563rem;
      }
    }
  }
}
/* End:: Checkbox buttons */

.btn-outline-secondary {
  --bs-btn-hover-color: #{$secondary};
  --bs-btn-hover-bg: #{$white};
  --bs-btn-hover-border-color: #{$secondary};
  --bs-btn-active-color: #{$secondary};
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: $default-border;
}
.btn:hover {
  border-color: $default-border;
}
